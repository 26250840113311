<template>
    <b-modal ref="issueInvoices" :title="$t('invoices.issuing_invoices')" @hide="clearData" size="lg" content-class="bModalExpand">
        <template #default>
            <div>
                <div style="padding-bottom: 10px;">
                    <validation-observer ref="validation">
                        <b-form-group :label="$t('invoices.period')">
                            <validation-provider #default="{ errors }" :name="$t('invoices.period')" rules="required">
                                <date-picker :first-day-of-week="2" v-model="addObject.range" :masks="{ input: 'DD.MM.YYYY'}" :update-on-input="false" :model-config="modelConfig" is-range :is-required="true" @input="dateChanged">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <input class="form-control"
                                               :value="formatDate(inputValue)"
                                               v-on="inputEvents.start"
                                               :disabled="true"
                                        />
                                    </template>
                                </date-picker>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </validation-observer>
                </div>
                <b-form-group :label="$t('invoices.unissued_invoices')">
                    <TableWithCheckBox ref="tableWithInvoices" :columnDefs="columnDefs" :rowData="buyers" :selected="selectedBuyers" selectValue="buyer.id" :findValueInArray="true" @selectionChanged="selectionChanged"/>
                </b-form-group>
            </div>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="finishIssuingInvoices" :disabled="selectedBuyers.length === 0">
                <span class="align-middle">{{ $t('general.accept') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>


<script>
    import {BModal, BFormGroup, BButton} from 'bootstrap-vue'
    import {DatePicker} from 'v-calendar'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components:{
            TableWithCheckBox,
            BButton,
            BModal,
            DatePicker,
            ValidationObserver,
            ValidationProvider,
            BFormGroup
        },
        data() {
            return {
                addObject: {
                    range: {
                        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                    }
                },
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                columnDefs: [
                    { headerName: this.$t('table_fields.buyer'), field: 'buyer.name', filter: true, editable: false },
                    { headerName: this.$t('invoices.num_invoices'), field: 'num_invoices', filter: true, editable: false },
                    { headerName: this.$t('table_fields.total_price'), field: 'total_amount', filter: true, editable: false, cellRenderer: 'DisplayPrice' }
                ],
                userData: null,
                selectedBuyers: [],
                buyers: []
            }
        },
        methods: {
            async loadData() {
                try {
                    const startTime = this.$dayjs(this.addObject.range.start).format()
                    const endTime = this.$dayjs(this.addObject.range.end).format()

                    const payload = {
                        'start_date': startTime,
                        'end_date': endTime
                    }


                    const response = await this.$http.post('/api/client/v1/invoices/buyers/timeframe', payload)
                    this.buyers = response.data ?? []
                    this.selectedBuyers = this.buyers.map(obj => obj.buyer.id)

                    if (this.$refs.tableWithInvoices) {
                        await this.$refs.tableWithInvoices.refresh()
                        this.$refs.tableWithInvoices.updateSelected(this.selectedBuyers)
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async dateChanged() {
                await this.loadData()
            },
            async checkValidation() {
                return await this.$refs.validation.validate()
            },
            async finishIssuingInvoices() {
                const validation = this.checkValidation()

                if (validation) {
                    if (this.selectedBuyers.length > 0) {
                        try {
                            const payload = {
                                'start_date': this.addObject.range.start,
                                'end_date': this.addObject.range.end,
                                'buyer_ids': this.selectedBuyers,
                                'issued_by': this.userData
                            }

                            await this.$http.post('/api/client/v1/invoices/multiple', payload)
                            this.clearData()

                            this.$emit('finishedIssuing')
                            this.$refs.issueInvoices.hide()
                        } catch (err) {
                            Sentry.captureException(err)
                            this.$printError(this.$t('print.error.on_load_data'))
                        }
                    } else {
                        this.$printError(this.$t('print.error.empty_selection'))
                    }
                }
            },
            clearData() {
                this.addObject.range.start = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                this.addObject.range.end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                this.selectedBuyers = []
            },
            formatDate(inputValue) {
                return `${inputValue.start  } - ${  inputValue.end}`
            },
            async open() {
                await this.loadData()
                this.$refs.issueInvoices.show()
            },
            selectionChanged(newSelectedBuyers) {
                this.selectedBuyers = newSelectedBuyers
            }
        },
        mounted() {
            this.userData = this.$store.getters['user/userData']

            if (!this.userData) {
                this.userData = {
                    name: ''
                }
            }
        }
    }
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dark-layout input[disabled] {
  color: white !important;
}

.light-layout input[disabled] {
  color: #6e6b7b !important;
  background-color: transparent !important;
}

</style>

<style>
.bModalExpand{
  min-height: 90vh !important;
}

.unit-select .vs__selected-options {
  padding: unset !important;
}
</style>
